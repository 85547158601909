import React, { Component } from 'react';
import {
  message,
  Dropdown,
  Button,
  Form,
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  Collapse,
  Drawer,
  Table,
  Menu
} from 'antd';
import Options from '../../entities/Options';
import TickerSearchField from 'common/ui/TickerSearchField';
import client from 'features/order/api/client';
import researchClient from 'features/research/api/client';
import config from 'common/config';
import _, { upperCase } from 'lodash';
import { Message } from 'semantic-ui-react';
import { HotTable } from '@handsontable/react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import {
  pushVoteNoteGridColumns,
  pushVoteResearchLogGridColumns
} from '../GridColumnMap';
import {
  DIALOG_EXECUTE_SINGLE_TRADE,
  DIALOG_EXECUTE_CB_TRADES
} from '../../../order/orderConstants';
import JsonTooltipComponent from '../../../../common/ui/renderers/agGrid/JsonTooltipComponent';
import LinkPannel from './LinkPannel';
import CompanyScoreDetailDrawer from '../companyScore/drawer/CompanyScoreDetailDrawer';
import SingleTradeDialog from 'features/order/components/dialogs/SingleTradeDialog';
import CBBatchTradesDialog from 'features/order/components/dialogs/CBBatchTradesDialog';

const { TextArea } = Input;
const { Option } = Select;
const _createOptions = values => {
  return values.map(v => ({
    key: v,
    text: v,
    value: v
  }));
};

const { Panel } = Collapse;

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
  );
};

const EditableCell = ({
                        editing,
                        dataIndex,
                        title,
                        inputType,
                        record,
                        index,
                        children,
                        handlChange,
                        ...restProps
                      }) => {
  if (!record) return <td {...restProps}>{children}</td>;
  const editable =
      (dataIndex === 'notes' && record.indexName) ||
      [
        'LT-total score',
        'Valuation',
        'W.C./Rev',
        'Competition',
        'Mutual funds',
        'Hedge funds',
        'Individual investors',
        'Market research deepth'
      ].includes(record.indexName);
  const inputNode =
      inputType === 'number' ? (
          <Select
              size="small"
              style={{ width: '60px' }}
              defaultValue={record[dataIndex]}
              onChange={value => handlChange(record, dataIndex, value)}
          >
            <Select.Option value="10">10</Select.Option>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="30">30</Select.Option>
            <Select.Option value="40">40</Select.Option>
          </Select>
      ) : (
          <Input
              size="small"
              defaultValue={record[dataIndex]}
              onChange={e => handlChange(record, dataIndex, e.target.value)}
          />
      );
  return (
      <td {...restProps}>
        {editing && editable ? (
            <Form.Item
                name={dataIndex}
                style={{
                  margin: 0
                }}
            >
              {inputNode}
            </Form.Item>
        ) : (
            children
        )}
      </td>
  );
};

class AddOrUpdateDialog extends Component {
  constructor(props) {
    super(props);
    this.hotTblRef = React.createRef();
    this.state = {
      submitStatus: 'READY',
      modelOptions: [],
      errors: [],
      warnings: [],
      fileList: [],
      canEditFiledsMap: {
        CLOSE: ['closingReasons', 'closingSpecify'],
        ADJUST: [
          'type',
          'tickerShort',
          'tickerLong',
          'pmNotice',
          'attachList'
          // 'targetPriceLong',
          // 'targetPriceShrt'
        ],
        SUBMIT_UPDATE: [
          'confidenceLevel',
          // 'confidenceLevelReason',
          'summary',
          // 'catalyst',
          // 'chips',
          // 'risks',
          'targetPriceLong',
          'targetPriceShrt',
          'expectedReturn',
          'lossLimit'
        ]
      },
      gridWrapperStyle: {
        width: '1250px',
        height: '150px',
        marginTop: '5px',
        overflow: 'auto'
      },
      contextMenu: [
        'row_above',
        'row_below',
        '---',
        'col_left',
        'col_right',
        '---',
        'remove_row',
        'remove_col',
        'undo',
        'redo'
      ],
      tickerList: [],
      needCheck: false,
      researchLog: [],
      companyScoreData: [],
      columns: [
        {
          title: 'Company Score',
          dataIndex: 'indexGroup',
          render: (value, row) => ({
            children: value,
            props: { rowSpan: row.rowSpan || 0 }
          }),
          width: 100
        },
        {
          title: 'Factor Name',
          dataIndex: 'indexName',
          width: 100
        },
        {
          title: 'L-Weight(%)',
          dataIndex: 'longWeight',
          width: 25,
        },
        {
          title: 'company1',
          dataIndex: 'company1',
          width: 200
        },
        {
          title: 'S-Weight(%)',
          dataIndex: 'shrtWeight',
          width: 25,
        },
        {
          title: 'company2',
          dataIndex: 'company2',
          width: 200
        },
        {
          title: 'Notes',
          dataIndex: 'notes',
          width: 200,
          editable: true
        }
      ],
      companyScoreDrawer: {
        show: false,
        data: {}
      }
    };
  }

  componentDidMount() {
    this._initTickerList();
    this._loadResearchLogs();
    this._loadPairCompanyInfo();
  }

  _loadPairCompanyInfo = () => {
    const { data } = this.props.pushVotesModal;
    if (data && (data.id || data.tickerLong || data.tickerShort)) {
      researchClient
          .getPairCompanyInfo({
            id: data.id,
            longTiker: data.tickerLong,
            shrtTicker: data.tickerShort
          })
          .then(resp => {
            this.setState(
                {
                  companyScoreData: resp.data.map((r, index) => ({
                    key: index,
                    ...r
                  }))
                },
                this._checkCompany
            );
          })
          .catch(err => console.log(err));
    }
  };

  _checkCompany = () => {
    const { companyScoreData } = this.state;
    const { data, op } = this.props.pushVotesModal;
    if (_.isEmpty(companyScoreData) || ['DETAIL'].includes(op)) return;
    const warnings = [];
    const filterData = companyScoreData.filter(r =>
        ['Investment Conclusion', 'Investment direction'].includes(r.indexName)
    );
    const longDataFilter = filterData.filter(
        r => 'LONG' !== upperCase(r.company1 ? r.company1 : '')
    );
    const shrtDataFilter = filterData.filter(
        r => 'SHORT' !== upperCase(r.company2 ? r.company2 : '')
    );
    if (
        !_.isEmpty(longDataFilter) &&
        ['Alpha Long', 'Pair Trade'].includes(data.type)
    ) {
      warnings.push(
          `Your long ${data.tickerLong} is against your long-term/short-term investment direction.`
      );
    }
    if (
        !_.isEmpty(shrtDataFilter) &&
        ['Alpha Short', 'Pair Trade'].includes(data.type)
    ) {
      warnings.push(
          `Your short ${data.tickerShort} is against your long-term/short-term investment direction.`
      );
    }
    this.setState({
      warnings
    });
  };

  _initTickerList = () => {
    const { op } = this.props.pushVotesModal;
    if (op === 'DETAIL') return;
    researchClient
        .getLastCompanyList({ dayOffset: 1 })
        .then(resp => {
          const { tickerList, check } = resp.data;
          this.setState({
            tickerList: tickerList,
            needCheck: check
          });
        })
        .catch(err => console.log(err));
  };

  _loadResearchLogs = () => {
    const { op, data } = this.props.pushVotesModal;
    if (op === 'DETAIL') {
      researchClient
          .getResearchLog({
            refId: data.id,
            busType: 'STOCK_PITCH_SUBMIT_UPDATE'
          })
          .then(resp => {
            this.setState({
              researchLog: _.isEmpty(resp.data) ? [] : resp.data
            });
          })
          .catch(err => console.log(err));
    }
  };

  _getValidateMap = () => {
    return {
      type: true,
      tickerLong: true,
      startPriceLong: true,
      startPriceShrt: true,
      targetPriceLong: this._validateRule(this._showTickerLongTargetPrice),
      targetPriceShrt: this._validateRule(this._showTickerShrtTargetPrice),
      tickerShort: true,
      expectedReturn: this._validateRule(this._showExceptReturn),
      expectedDuration: true,
      //'shortingCost': true,
      // sourceOfAlphaList: false,
      // sourceOfAlphaSepcify: false,
      // longModel: this._validateRule(this._validateLongModel),
      // shrtModel: this._validateRule(this._validateShrtModel),
      closingReasons: this._validateClosingModel(),
      closingSpecify: this._validateClosingModel(),
      // summary: true,
      // catalyst: false,
      // chips: false,
      // risks: false,
      presentation: true,
      // attachVOList: this._validateRule(this._showUploadContent),
      pmNotice: this._validateRule(this._validateIsObserverModel),
      scoreData: true,
      confidenceLevel: true,
      lossLimit: true,
      valuation: false
    };
  };

  _closeModal = () => {
    this.props.closePushVotesModal({ key: 'op', value: 'CLOSE' });
    this.setState({
      fileList: []
    });
  };

  _initConfig = () => {
    const { pushVOtesPMList = [] } = this.props;
    const pmList = pushVOtesPMList;
    return { pmList };
  };

  _showSepcify = () => {
    const { data } = this.props.pushVotesModal;
    const show =
        data.sourceOfAlphaList &&
        (data.sourceOfAlphaList.includes('Other') ||
            data.sourceOfAlphaList.includes('Combination of the above'));
    return show ? 'block' : 'none';
  };

  _getSecurity = (ticker, priceFiledName, nameField, tickerField) => {
    if (!ticker) {
      return;
    }

    this.setState({ isLoadingSecurity: true });
    client
        .getSecurities([{ ticker }])
        .then(([security]) => {
          this.onInputChange({ name: tickerField, value: security.ticker });
          this.onInputChange({ name: priceFiledName, value: security.price });
          this.onInputChange({ name: nameField, value: security.name });
        })
        .catch(_ => {});
  };

  onLongTickerSelect = ticker => {
    this._getSecurity(ticker, 'startPriceLong', 'tickerNameLong', 'tickerLong');
  };

  onShortTickerSelect = ticker => {
    this._getSecurity(
        ticker,
        'startPriceShrt',
        'tickerNameShort',
        'tickerShort'
    );
  };

  onInputChange = ({ name, value }) => {
    const { data } = this.props.pushVotesModal;
    const updateData = {
      ...data,
      [name]: value
    };
    if (
        name === 'type' &&
        ['Alpha Short', 'Pair Trade'].includes(value) &&
        !data.shortingCost
    ) {
      updateData['shortingCost'] = 6;
    } else if (name === 'type' && ['Alpha Long'].includes(value)) {
      updateData['shortingCost'] = null;
    }

    this.props.changePushVotesModalInput(updateData);
    if (
        ['tickerLong', 'tickerShort'].includes(name) &&
        value &&
        (value.endsWith('Equity') || value.endsWith('EQUITY'))
    ) {
      _.delay(() => {
        this._loadPairCompanyInfo();
      }, 500);
    }
  };

  onUploadChange = info => {
    const { status } = info.file;
    let fileList = [...info.fileList];

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
        file.filePath = file.response.filePath;
      }
      return file;
    });

    this.setState({ fileList });
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  _readOnlyMode = field => {
    const { data } = this.props.pushVotesModal;
    const { op } = this.props.pushVotesModal;
    const { canEditFiledsMap } = this.state;
    if (canEditFiledsMap[op]) {
      if (op === 'CLOSE') return !(canEditFiledsMap[op].indexOf(field) >= 0);
      if (op === 'ADJUST') return canEditFiledsMap[op].indexOf(field) >= 0;
    }
    if (op === 'UPDATE' && data.status === 'SUBMIT') {
      return !(canEditFiledsMap[`SUBMIT_UPDATE`].indexOf(field) >= 0);
    }
    return op === 'DETAIL';
  };

  _showUploadContent = () => {
    const { data } = this.props.pushVotesModal;
    return data.presentation === 'Y' ? 'block' : 'none';
  };

  _onPresentationChange = () => {
    const { data } = this.props.pushVotesModal;
    const value = data.presentation === 'Y' ? 'N' : 'Y';
    this.onInputChange({ name: 'presentation', value });
  };

  _showTickerLongTargetPrice = () => {
    const { data } = this.props.pushVotesModal;
    return data.type === 'Alpha Long' ? 'block' : 'none';
  };

  _showTickerShrtTargetPrice = () => {
    const { data } = this.props.pushVotesModal;
    return data.type === 'Alpha Short' ? 'block' : 'none';
  };

  _showExceptReturn = () => {
    const { data } = this.props.pushVotesModal;
    return data.type === 'Pair Trade' ? 'block' : 'none';
  };

  _validateRule = fn => {
    const { op } = this.props.pushVotesModal;
    let require = true;
    let rules = false;
    if (fn) {
      const display = fn();
      require = display === 'block' || display === true;
    }
    if (require) {
      rules = !['CLOSE', 'ADJUST'].includes(op);
    }
    return rules;
  };

  _validateLongModel = () => {
    const { data } = this.props.pushVotesModal;
    return data.type === 'Alpha Long' || data.type === 'Pair Trade';
  };

  _validateShrtModel = () => {
    const { data } = this.props.pushVotesModal;
    return data.type === 'Alpha Short' || data.type === 'Pair Trade';
  };

  _validateIsObserverModel = () => {
    const { data } = this.props.pushVotesModal;
    return data.isObserver === false;
  };

  _validateClosingModel = () => {
    const { op } = this.props.pushVotesModal;
    return op === 'CLOSE';
  };

  onSaveBtnClicked = () => {
    this.saveData('NEW', 1);
  };

  onSubmitBtnClicked = () => {
    this.saveData('SUBMIT', 1);
  };

  saveData = (status, isActive) => {
    const { data } = this.props.pushVotesModal;
    const { fileList, companyScoreData } = this.state;
    const submitData = {
      ...data,
      status,
      isActive,
      companyScoreInfos: companyScoreData
          ? companyScoreData.map(r => ({
            key: `${r.indexGroup}_${r.indexName}`,
            notes: r.notes
          }))
          : []
    };
    if (!_.isEmpty(fileList)) {
      const fileArr = fileList.map((e, index) => {
        const file = e;
        return {
          id: file.id,
          uid: 100 + index,
          name: file.name,
          uri: file.filePath,
          url: file.filePath
        };
      });
      submitData.attachVOList = fileArr;
    }
    const errors = this._validateData(submitData);
    this.setState({
      errors
    });
    if (!_.isEmpty(errors)) {
      return;
    }
    this.setState({
      submitStatus: 'SUBMITTING'
    });
    try {
      this.props.savePushVotes(submitData);
      message.success(`Submit success`);
    } catch (err) {
      console.log(err);
    }
    this.setState({
      fileList: [],
      submitStatus: 'READY'
    });
  };

  _validateData = data => {
    const errors = [];
    const validateMap = this._getValidateMap();
    _.map(validateMap, (value, key) => {
      if (value && !data[key]) errors.push(`${key} cannot be null`);
    });
    return errors;
  };

  _createScoreTable = data => {
    const { gridWrapperStyle } = this.state;
    const currentData = data ? data : [[]];
    return (
        <div style={gridWrapperStyle}>
          <HotTable
              ref={this.hotTblRef}
              data={currentData}
              preventOverflow="horizontal"
              height={'145px'}
              width={'1240px'}
              contextMenu={this.state.contextMenu}
              afterChange={this._afterCellChangeHandle}
              licenseKey="non-commercial-and-evaluation"
              minSpareRows={1}
              minSpareCols={1}
              autoRowSize={true}
              //events
          ></HotTable>
        </div>
    );
  };

  _afterCellChangeHandle = async (value, action) => {
    if (
        !this.hotTblRef ||
        !this.hotTblRef.current ||
        ['loadData'].indexOf(action) > -1
    )
      return;
    const tableData = this.hotTblRef.current.hotInstance.getData();
    _.delay(() => {
      this.onInputChange({ name: 'score', value: tableData });
    }, 100);
  };

  _onPositionChange = value => {
    const { data } = this.props.pushVotesModal;
    const updateData = {
      ...data,
      longPosition: value,
      shrtPosition: value
    };
    this.props.changePushVotesModalInput(updateData);
  };

  _createLongTickerField = () => {
    const { tickerList, needCheck } = this.state;
    const { data } = this.props.pushVotesModal;
    if (!data) return;
    const { type } = data;
    if (!type) {
      return <Select disabled={true} />;
    }
    if (['Alpha Long', 'Pair Trade'].includes(type) && needCheck) {
      return (
          <Select
              disabled={this._readOnlyMode('tickerLong')}
              options={_createOptions(tickerList)}
              value={data.tickerLong}
              showSearch
              placeholder="Select Long Ticker"
              onChange={(e, { value }) => {
                this.onInputChange({ name: 'tickerLong', value: value });
                this.onLongTickerSelect(value);
              }}
          />
      );
    } else {
      return (
          <TickerSearchField
              name="tickerLong"
              value={data.tickerLong}
              onChange={({ name, value }) => {
                this.onInputChange({ name: 'tickerLong', value });
              }}
              selectTicker={this.onLongTickerSelect}
          />
      );
    }
  };

  _createShortTickerField = () => {
    const { tickerList, needCheck } = this.state;
    const { data } = this.props.pushVotesModal;
    if (!data) return;
    const { type } = data;
    if (!type) {
      return <Select disabled={true} />;
    }
    if (['Alpha Short', 'Pair Trade'].includes(type) && needCheck) {
      return (
          <Select
              disabled={this._readOnlyMode('tickerShort')}
              showSearch
              options={_createOptions(tickerList)}
              value={data.tickerShort}
              placeholder="Select Short Ticker"
              onChange={(e, { value }) => {
                this.onInputChange({ name: 'tickerShort', value: value });
                this.onShortTickerSelect(value);
              }}
          />
      );
    } else {
      return (
          <TickerSearchField
              name="tickerShort"
              value={data.tickerShort}
              onChange={({ name, value }) => {
                this.onInputChange({ name: 'tickerShort', value });
              }}
              selectTicker={this.onShortTickerSelect}
          />
      );
    }
  };

  _createAttachList = () => {
    const { data } = this.props.pushVotesModal;
    return (
        <LinkPannel
            {...this.props}
            data={data}
            readOnly={this._readOnlyMode('attachList')}
        />
    );
  };

  _createForm = () => {
    const { data, op } = this.props.pushVotesModal;
    if (_.isEmpty(this.state.fileList) && data.attachVOList) {
      data.attachVOList.forEach(r => {
        if (r.id) {
          r.url = this.getUrlLink(r);
        }
      });

      this.setState({
        fileList: data.attachVOList
      });
    }
    const configData = this._initConfig();
    const { pmList } = configData;
    return (
        <Form
            id="pushVotesForm"
            onFinish={this.saveData}
            labelAlign="left"
            style={{ maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden' }}
        >
          <style>
            {`
                #pushVotesForm .ui.input {
                  width: 100%
                }
                    
                #pushVotesForm .ant-tabs-bar {
                  margin: 0 0 8px 0;
                  color: black;
                }

                #pushVotesForm .ant-tabs-nav .ant-tabs-tab-active {
                  /* background-color: #FF9E28; */
                  color: black;
                }

                #pushVotesForm .anticon{
                  color: black;
                }

                #pushVotesForm .ant-input[disabled]{
                  color: black;
                }

                #pushVotesForm .ant-select-selector{
                  color: black;
                }

                #pushVotesForm .ant-input-number-disabled{
                  color: black;
                }

                #pushVotesForm .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
                  padding: 1px 1px;
                } 
            `}
          </style>
          <Row span={24}>
            <Col span={12}>
              <Form.Item
                  label="Type"
                  required={this._validateRule() && !this._readOnlyMode('type')}
              >
                <Select
                    disabled={this._readOnlyMode('type')}
                    options={Options.typeList}
                    value={data.type}
                    placeholder="Select Type"
                    onChange={(e, { value }) =>
                        this.onInputChange({ name: 'type', value: value })
                    }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                  required={
                    this._validateRule() && !this._readOnlyMode('confidenceLevel')
                  }
                  label="Confidence Level"
              >
                <Select
                    disabled={this._readOnlyMode('confidenceLevel')}
                    options={Options.confidenceLevelList}
                    value={data.confidenceLevel}
                    placeholder="confidenceLevel"
                    onChange={(e, { value }) =>
                        this.onInputChange({ name: 'confidenceLevel', value })
                    }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row span={24}>
            <Col span={6}>
              <Form.Item
                  required={
                    this._validateRule() && !this._readOnlyMode('tickerLong')
                  }
                  label="Ticker Long"
              >
                <div className={'ui form field'}>
                  {this._readOnlyMode('tickerLong') ? (
                      <Input disabled={true} value={data.tickerLong}></Input>
                  ) : (
                      this._createLongTickerField()
                  )}
                </div>
              </Form.Item>
            </Col>
            <Col span={9} style={{ marginLeft: '10px' }}>
              <Form.Item label="Name">
                <Input
                    disabled={true}
                    value={data.tickerNameLong}
                    style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={4} style={{ marginLeft: '10px' }}>
              <Form.Item label="Starting Price">
                <Input
                    disabled={true}
                    value={data.startPriceLong}
                    style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
                span={4}
                style={{
                  marginLeft: '10px',
                  display: this._showTickerLongTargetPrice()
                }}
            >
              <Form.Item
                  required={
                    this._validateRule(this._showTickerLongTargetPrice) &&
                    !this._readOnlyMode('targetPriceLong')
                  }
                  label="Target Price"
              >
                <InputNumber
                    disabled={this._readOnlyMode('targetPriceLong')}
                    value={data.targetPriceLong}
                    onChange={value =>
                        this.onInputChange({ name: 'targetPriceLong', value })
                    }
                    style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row span={24}>
            <Col span={6}>
              <Form.Item
                  required={
                    this._validateRule() && !this._readOnlyMode('tickerShort')
                  }
                  label="Ticker Short"
              >
                <div className="ui form field">
                  {this._readOnlyMode('tickerShort') ? (
                      <Input disabled={true} value={data.tickerShort} />
                  ) : (
                      this._createShortTickerField()
                  )}
                </div>
              </Form.Item>
            </Col>
            <Col span={9} style={{ marginLeft: '10px' }}>
              <Form.Item label="Name">
                <Input
                    disabled={true}
                    value={data.tickerNameShort}
                    style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={4} style={{ marginLeft: '10px' }}>
              <Form.Item label="Starting Price">
                <Input
                    disabled={true}
                    value={data.startPriceShrt}
                    style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
                span={4}
                style={{
                  marginLeft: '10px',
                  display: this._showTickerShrtTargetPrice()
                }}
            >
              <Form.Item
                  label="Target Price"
                  required={
                    this._validateRule(this._showTickerShrtTargetPrice) &&
                    !this._readOnlyMode('targetPriceShrt')
                  }
              >
                <InputNumber
                    disabled={this._readOnlyMode('targetPriceShrt')}
                    value={data.targetPriceShrt}
                    onChange={value =>
                        this.onInputChange({ name: 'targetPriceShrt', value })
                    }
                    style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12} style={{ display: this._showExceptReturn() }}>
              <Form.Item
                  required={
                    this._validateRule(this._showExceptReturn) &&
                    !this._readOnlyMode('expectedReturn')
                  }
                  label="Expected Return %"
              >
                <InputNumber
                    disabled={this._readOnlyMode('expectedReturn')}
                    value={data.expectedReturn}
                    formatter={value => `${value}%`}
                    style={{ width: '100%' }}
                    onChange={value =>
                        this.onInputChange({ name: 'expectedReturn', value })
                    }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  required={
                    this._validateRule() && !this._readOnlyMode('lossLimit')
                  }
                  label="Loss Limit %"
              >
                <InputNumber
                    disabled={this._readOnlyMode('lossLimit')}
                    value={data.lossLimit}
                    formatter={value => `${value}%`}
                    style={{ width: '100%' }}
                    onChange={value =>
                        this.onInputChange({
                          name: 'lossLimit',
                          value: value < 0 ? value : value === 0 ? -1 : -value
                        })
                    }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  required={
                    this._validateRule() && !this._readOnlyMode('expectedDuration')
                  }
                  label="Expected Duration"
              >
                <Select
                    disabled={this._readOnlyMode('expectedDuration')}
                    options={Options.expectedDuration}
                    value={data.expectedDuration}
                    placeholder="Select Expected Duration"
                    onChange={(e, { value }) =>
                        this.onInputChange({ name: 'expectedDuration', value })
                    }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Shorting cost per year">
                <InputNumber
                    disabled={this._readOnlyMode('shortingCost')}
                    value={data.shortingCost}
                    formatter={value => `${value}%`}
                    style={{ width: '100%' }}
                    onChange={value =>
                        this.onInputChange({ name: 'shortingCost', value })
                    }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                  label="Summary"
                  labelCol={{
                    flex: '80px'
                  }}
                  required={this._validateRule() && !this._readOnlyMode('summary')}
              >
                <Input.TextArea
                    rows={3}
                    disabled={this._readOnlyMode('summary')}
                    value={data.summary}
                    onChange={e => {
                      this.onInputChange({
                        name: 'summary',
                        value: e.target.value
                      });
                    }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
          <Col span={24}>
            <Form.Item label="Source of Alpha">
              <Select
                disabled={this._readOnlyMode('sourceOfAlphaList')}
                mode="multiple"
                options={Options.sourceOfAlpha}
                value={data.sourceOfAlphaList}
                placeholder="Select Source of Alpha"
                onChange={value =>
                  this.onInputChange({ name: 'sourceOfAlphaList', value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Valuation">
              <Input
                disabled={this._readOnlyMode('valuation')}
                value={data.valuation}
                placeholder="Input valution"
                onChange={e =>
                  this.onInputChange({
                    name: 'valuation',
                    value: e.target.value
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ display: this._showSepcify() }}>
            <Form.Item
              required={
                this._validateRule(this._showSepcify) &&
                !this._readOnlyMode('sourceOfAlphaSepcify')
              }
              label="Sepcify"
            >
              <Input
                value={data.sourceOfAlphaSepcify}
                disabled={this._readOnlyMode('sourceOfAlphaSepcify')}
                onChange={e =>
                  this.onInputChange({
                    name: 'sourceOfAlphaSepcify',
                    value: e.target.value
                  })
                }
              />
            </Form.Item>
          </Col>
          {this._validateRule(this._validateLongModel) && (
            <Col span={24}>
              <Form.Item
                required={
                  this._validateRule(this._validateLongModel) &&
                  !this._readOnlyMode('longModel')
                }
                label="Long Model"
              >
                <Select
                  disabled={this._readOnlyMode('longModel')}
                  options={Options.longModelList}
                  value={data.longModel}
                  placeholder="Select Long Model"
                  onChange={(e, { value }) =>
                    this.onInputChange({ name: 'longModel', value })
                  }
                />
              </Form.Item>
            </Col>
          )}
          {this._validateRule(this._validateShrtModel) && (
            <Col span={24}>
              <Form.Item
                required={
                  this._validateRule(this._validateShrtModel) &&
                  !this._readOnlyMode('shrtModel')
                }
                label="Short Model"
              >
                <Select
                  disabled={this._readOnlyMode('shrtModel')}
                  options={Options.shortModelList}
                  value={data.shrtModel}
                  placeholder="Select Short Model"
                  onChange={(e, { value }) =>
                    this.onInputChange({ name: 'shrtModel', value })
                  }
                />
              </Form.Item>
            </Col>
          )}
        </Row> */}

          {/* <Col span={18}>
            <Form.Item label="Confidence Level Reason">
              <Input
                disabled={this._readOnlyMode('confidenceLevelReason')}
                value={data.confidenceLevelReason}
                style={{ width: '100%' }}
                onChange={event =>
                  this.onInputChange({
                    name: 'confidenceLevelReason',
                    value: event.target.value
                  })
                }
              />
            </Form.Item>
          </Col> */}

          {/* <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Summary"
              labelCol={{
                flex: '80px'
              }}
              required={this._validateRule() && !this._readOnlyMode('summary')}
            >
              <Input.TextArea
                rows={4}
                disabled={this._readOnlyMode('summary')}
                value={data.summary}
                onChange={e => {
                  this.onInputChange({
                    name: 'summary',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Catalyst & Key Indicator"
              labelCol={{
                flex: '170px'
              }}
            >
              <Input.TextArea
                rows={4}
                disabled={this._readOnlyMode('catalyst')}
                value={data.catalyst}
                onChange={e => {
                  this.onInputChange({
                    name: 'catalyst',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Risks"
              labelCol={{
                flex: '80px'
              }}
            >
              <Input.TextArea
                rows={2}
                disabled={this._readOnlyMode('risks')}
                value={data.risks}
                onChange={e => {
                  this.onInputChange({
                    name: 'risks',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Chips and flows"
              labelCol={{
                flex: '170px'
              }}
            >
              <Input.TextArea
                rows={2}
                disabled={this._readOnlyMode('chips')}
                value={data.chips}
                onChange={e => {
                  this.onInputChange({
                    name: 'chips',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row> */}

          {!_.isEmpty(this.state.researchLog) && (
              <Collapse>
                <Panel header="Change Log" key="1">
                  <Col span={24}>
                    <div className={`grid-wrapper`} style={{ height: '160px' }}>
                      <AgGridReact
                          // properties
                          columnDefs={pushVoteResearchLogGridColumns}
                          rowData={this.state.researchLog}
                          suppressAggFuncInHeader={true}
                          deltaRowDataMode={true}
                          animateRows={false}
                          enableRangeSelection={true}
                          rowSelection="single"
                          frameworkComponents={{
                            jsonTooltipComponent: JsonTooltipComponent
                          }}
                      />
                    </div>
                  </Col>
                </Panel>
              </Collapse>
          )}

          <Row gutter={24} style={{ marginTop: '5px' }}>
            {/* <Col span={24}>
            <Form.Item
              required={
                this._validateRule() && !this._readOnlyMode('presentation')
              }
              label="Presentation"
            >
              <Checkbox
                value={data.presentation}
                checked={data.presentation === 'Y'}
                disabled={this._readOnlyMode('presentation')}
                onChange={this._onPresentationChange}
              />
            </Form.Item>
          </Col> */}
            {/* <Col span={24}>
            <Form.Item
              required={this._validateRule(this._showUploadContent)}
              label="Attachment"
            >
              {
                <Upload
                  fileList={this.state.fileList}
                  action={`${config.api.RESEARCH_URL}/file/upload`}
                  onChange={this.onUploadChange}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              }
            </Form.Item>
          </Col> */}
            {this._showPmNotice() && op !== 'DETAIL' && (
                <Col span={24}>
                  <Form.Item
                      required={
                        this._validateRule() && !this._readOnlyMode('pmNotice')
                      }
                      label="PMs notified"
                  >
                    <Select
                        mode="multiple"
                        disabled={this._readOnlyMode('pmNotice')}
                        value={data.pmNotice}
                        optionFilterProp="label"
                        onChange={selectedItems =>
                            this.onInputChange({
                              name: 'pmNotice',
                              value: selectedItems
                            })
                        }
                    >
                      {pmList.map(item => (
                          <Option
                              key={item.userAccountId}
                              value={item.userAccountId}
                              label={item.englishName}
                          >
                            {item.englishName}
                          </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
            )}
            {this._showCloseReason() && (
                <>
                  <Col span={24}>
                    <Form.Item
                        required={!this._readOnlyMode('closingReasons')}
                        label="Close Reason"
                    >
                      <Select
                          mode="multiple"
                          disabled={this._readOnlyMode('closingReasons')}
                          options={Options.closingReasonList}
                          value={data.closingReasons}
                          onChange={selectedItems =>
                              this.onInputChange({
                                name: 'closingReasons',
                                value: selectedItems
                              })
                          }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <b>What did you learn? Were you wrong?</b>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                        label=""
                        colon={false}
                        required={!this._readOnlyMode('closingSpecify')}
                    >
                      <TextArea
                          showCount
                          disabled={this._readOnlyMode('closingSpecify')}
                          maxLength={1000}
                          style={{ height: 100 }}
                          value={data.closingSpecify}
                          onChange={e =>
                              this.onInputChange({
                                name: 'closingSpecify',
                                value: e.target.value
                              })
                          }
                      />
                    </Form.Item>
                  </Col>
                </>
            )}
            <Col span={24}>
              <Form.Item
                  label="Attach"
                  labelCol={{
                    flex: '50px'
                  }}
              >
                {this._createAttachList()}
              </Form.Item>
            </Col>
            <Col span={24}>{this._createCompanyScorePannel()}</Col>
            {this._showNotes() && (
                <>
                  <Col span={24}>
                    <Form.Item
                        label="Notes"
                        labelCol={{
                          flex: '80px'
                        }}
                    >
                      <div className={`grid-wrapper`} style={{ height: '160px' }}>
                        <AgGridReact
                            // properties
                            columnDefs={pushVoteNoteGridColumns}
                            defaultColDef={pushVoteNoteGridColumns}
                            rowData={data.pushVoteNoteData}
                            suppressAggFuncInHeader={true}
                            deltaRowDataMode={true}
                            animateRows={false}
                            enableRangeSelection={true}
                            rowSelection="single"
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </>
            )}
          </Row>
          <Row>
            <Col span={24}>{this._createNotes()}</Col>
          </Row>
        </Form>
    );
  };

  getUrlLink = file => {
    if (!file) {
      return;
    }
    const name = file['name'];
    var type = name.substring(name.lastIndexOf('.'), name.length);
    let host = window.location.host;
    let uri = config.api.RESEARCH_URL;
    if (host.indexOf('ims') === 0) {
      host = 'https://' + host;
    } else {
      host = 'http://' + host;
    }
    uri = uri === '/rsrch' ? host + uri : uri;
    const id = file['id'];
    if (type === '.pptx' || type === '.xlsx' || type === '.docx') {
      const url = `https://view.officeapps.live.com/op/view.aspx?src=${uri}/file/review/${id}`;
      return url;
    } else {
      return `${uri}/file/review/${id}`;
      //message.error(`Preview only support pptx/xlsx/docx file`);
    }
  };

  onCloseBtnClicked = () => {
    this.saveData('SUBMIT', 0);
  };

  onAdjustBtnClicked = () => {
    const { data } = this.props.pushVotesModal;

    const { fileList } = this.state;

    const submitData = {
      ...data
    };
    if (!_.isEmpty(fileList)) {
      const fileArr = fileList.map((e, index) => {
        const file = e;
        return {
          id: file.id,
          uid: 100 + index,
          name: file.name,
          uri: file.url,
          url: file.url
        };
      });
      submitData.attachVOList = fileArr;
    }
    const errors = this._validateData(submitData);
    this.setState({
      errors
    });
    if (!_.isEmpty(errors)) {
      return;
    }
    try {
      this.props.adjustPushVotes(data);
    } catch (err) {
      console.log(err);
    }
    this.setState({
      fileList: []
    });
  };

  _showCloseReason = () => {
    const { data, op } = this.props.pushVotesModal;
    if (!['CLOSE', 'DETAIL'].includes(op)) return;
    if ('DETAIL' === op && data.isActive === 1) return;
    return true;
  };

  _showPmNotice = () => {
    const { data } = this.props.pushVotesModal;
    return data.isObserver === false;
  };

  _showNotes = () => {
    const { op } = this.props.pushVotesModal;
    if (['DETAIL'].includes(op)) return true;
    return false;
  };

  _createNotes = () => {
    const { op } = this.props.pushVotesModal;
    return (
        !['DETAIL'].includes(op) && (
            <div
                style={
                  {
                    // position: 'fixed',
                    // bottom: 10,
                    // width: '100%',
                    // //backgroundColor: 'lightblue',
                    // padding: '10px',
                    // textAlign: 'left'
                  }
                }
            >
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                <b style={{ fontSize: '18px' }}>Notes: </b>
              </p>
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                <b style={{ fontSize: '14px' }}>
                  {
                    '1. You can only add or adjust a stock pitch with a 24 hours Company Score update record. '
                  }
                </b>
              </p>
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                <b style={{ fontSize: '14px' }}>
                  {
                    '2. Start price is T (Submit Date, 00:00 HKT) +1 close price, close price is T (Close date, 00:00 HKT)+1 close price. '
                  }
                </b>
              </p>
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                <b style={{ fontSize: '14px' }}>
                  {
                    '3. Shorting cost is the cost of borrowing a stock. Our default shorting cost per year is 6%. Shorting cost = shorting cost per year * (today or close date - start date) / 365. '
                  }
                </b>
              </p>
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                <b style={{ fontSize: '14px' }}>
                  {
                    '4. Check Short List function in our IMS system to find out shorting cost of specific stock. '
                  }
                </b>
              </p>
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                <b style={{ fontSize: '14px' }}>
                  {'5. Total return = long retrun + short return - shorting cost. '}
                </b>
              </p>
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                <b style={{ fontSize: '14px' }}>
                  {
                    '6. The loss limit is the point at which you would close this position if your investment thesis is proven wrong. '
                  }
                </b>
              </p>
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                <b style={{ fontSize: '14px' }}>
                  {
                    '7. Suggested Size calculation is based on Kelly Criterion, which is 1%*(-Wining probability/loss limit-(1-Wining probability)/expected return). Expected return=target price/starting price-1 or expected return of this pair trade. '
                  }
                </b>
              </p>
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                <b style={{ fontSize: '14px' }}>
                  {
                    '8. The win probability for confidence levels are as follows: 1 corresponds to 30%, 2 corresponds to 40%, 3 corresponds to 50%, 4 corresponds to 60%, and 5 corresponds to 70%.'
                  }
                </b>
              </p>
            </div>
        )
    );
  };

  _createCompanyScoreDrawer = () => {
    const { companyScoreDrawer } = this.state;
    if (!companyScoreDrawer.show) return;
    return (
        <CompanyScoreDetailDrawer
            {...this.props}
            {...companyScoreDrawer}
            closeDrawer={this._onCloseCompanyScoreDrawer}
            reloadData={() => {
              this._loadPairCompanyInfo();
            }}
        />
    );
  };

  _onCloseCompanyScoreDrawer = () => {
    this.setState({
      companyScoreDrawer: {
        show: false,
        data: {}
      }
    });
  };

  _openCompanyScore = async ticker => {
    const { data } = this.props.pushVotesModal;
    // get company score info
    const resp = await researchClient.findCompanyScoreDetailByTicker({
      id: data.id,
      ticker
    });
    if (resp.data.id) {
      this.setState({
        companyScoreDrawer: {
          show: true,
          data: resp.data
        }
      });
    } else {
      message.warning("Can't find relation company score record.");
    }
  };

  _createCompanyScorePannel = () => {
    const { data, op } = this.props.pushVotesModal;
    const { companyScoreData } = this.state;
    if (_.isEmpty(data) || _.isEmpty(companyScoreData)) return;
    //data.companyScoreInfo
    const cols = this.mergedColumns(
        this.state.columns.map(r => {
          if (r.dataIndex === 'company1') {
            r.title =
                op === 'ADD' || op === 'UPDATE' ? (
                    <Button
                        size="small"
                        type="link"
                        onClick={() => this._openCompanyScore(data.tickerLong)}
                    >
                      {data.tickerLong}
                    </Button>
                ) : (
                    data.tickerLong
                );
          }
          if (r.dataIndex === 'company2') {
            r.title =
                op === 'ADD' || op === 'UPDATE' ? (
                    <Button
                        size="small"
                        type="link"
                        onClick={() => this._openCompanyScore(data.tickerShort)}
                    >
                      {data.tickerShort}
                    </Button>
                ) : (
                    data.tickerShort
                );
          }
          return { ...r };
        }),
        op === 'ADD' || op === 'UPDATE'
    );
    // const totalWeight = _.isEmpty(companyScoreData)
    //     ? 0
    //     : _.sum(
    //         companyScoreData.map(r => {
    //           if (r.weight) return parseInt(r.weight);
    //           return 0;
    //         })
    //     );
    const showData = companyScoreData;
    return (
        <Table
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell
              }
            }}
            columns={cols}
            dataSource={showData}
            pagination={false}
        />
    );
  };

  mergedColumns = (cols, editable) => {
    return cols.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'weight' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: ['weight', 'notes'].includes(col.dataIndex) && editable,
          handlChange: this.handlCompanyScoreChange
        })
      };
    });
  };

  handlCompanyScoreChange = (record, field, value) => {
    const { companyScoreData } = this.state;
    const updateData = companyScoreData.map((r, index) => {
      if (index === record.key) {
        return {
          ...r,
          [field]: value
        };
      }
      return { ...r };
    });
    this.setState({
      companyScoreData: updateData
    });
  };

  _handleMenuClick = e => {
    const { data } = this.props.pushVotesModal;
    let defaultFields = {};
    if (e.key === 'long') {
      defaultFields = {
        ticker: data.tickerLong,
        side: 'BUY'
      };
    } else if (e.key === 'short') {
      defaultFields = {
        ticker: data.tickerShort,
        side: 'SHRT'
      };
    } else if (e.key === 'pair') {
      defaultFields = {
        tickerShort: data.tickerShort,
        tickerLong: data.tickerLong
      };
    } else {
      return;
    }
    const dialogCode =
        e.key === 'pair' ? DIALOG_EXECUTE_CB_TRADES : DIALOG_EXECUTE_SINGLE_TRADE;
    this.props.openDialog(dialogCode, {
      createNew: true,
      defaultFields
    });
  };

  _createDialogs = () => {
    const { dialogs } = this.props;
    if (!dialogs) return;
    return Object.keys(dialogs).map(dialogCode => {
      const dialog = dialogs[dialogCode];

      return dialog.isOpened
          ? this._createDialog(dialogCode, dialog.info)
          : null;
    });
  };

  _createDialog = (dialogCode, info) => {
    const { settings, liveRiskInfos } = this.props;

    switch (dialogCode) {
      case DIALOG_EXECUTE_SINGLE_TRADE:
        return (
            <SingleTradeDialog
                key={dialogCode}
                info={info}
                closeDialog={this.closeTradeDialog}
                submitDialogSuccess={this.props.submitDialogSuccess}
                settings={settings}
                liveRiskInfos={liveRiskInfos}
            />
        );
      case DIALOG_EXECUTE_CB_TRADES:
        return (
            <CBBatchTradesDialog
                key={dialogCode}
                info={info}
                closeDialog={this.closeTradeDialog}
                openDialog={this.props.openDialog}
                submitDialogSuccess={this.props.submitDialogSuccess}
                settings={settings}
                liveRiskInfos={liveRiskInfos}
            />
        );
      default:
        return null;
    }
  };

  closeTradeDialog = dialogCode => {
    this.props.closeDialog(dialogCode);
  };

  _createTradeMenu = () => {
    return (
        <Menu onClick={this._handleMenuClick}>
          <Menu.Item key="long">Long</Menu.Item>
          <Menu.Item key="short">Short</Menu.Item>
          <Menu.Item key="pair">Pair</Menu.Item>
        </Menu>
    );
  };

  render() {
    const { pushVotesModal } = this.props;
    const { errors, warnings } = this.state;
    const { pushVotesSubmitStatus, pushVotesSubmitErrMsg } = this.props;
    const { op, data } = pushVotesModal;
    const errorList = [...errors, pushVotesSubmitErrMsg].filter(r => r);
    let concatShow = '';
    if (op === 'DETAIL' && data.updateTime) {
      concatShow = `${
          data.submitDate
              ? `, Submited: ${moment(data.submitDate).format('YYYY-MM-DD')}, `
              : ''
      }Updated: ${moment(data.updateTime).format('YYYY-MM-DD')}`;
    }
    let suggestedPositionShow = '';
    if (op !== 'DETAIL' && data.suggestedPosition) {
      suggestedPositionShow = ` Suggest Size: ${data.suggestedPosition}%`;
    }

    const title = (
        <div>
          <div style={{ float: 'left', width: '40%', textAlign: 'left' }}>
          <span style={{ color: '#FF9E28' }}>
            {data.ticker ? data.ticker : ''}
          </span>
            <span>{`Stock Pitch(${data.analystName}${
                concatShow ? concatShow : ''
            })`}</span>
            <span style={{ color: 'red' }}>{suggestedPositionShow}</span>
          </div>
          <div style={{ float: 'right', width: '60%', textAlign: 'right' }}>
            <div key="submitData" style={{ textAlign: 'right' }}>
              {(op === 'ADD' || op === 'UPDATE') && data.status === 'NEW' && (
                  <Button
                      size="small"
                      type="primary"
                      style={{ marginRight: '5px' }}
                      disabled={pushVotesSubmitStatus !== 'READY'}
                      onClick={() => {
                        this.onSaveBtnClicked();
                      }}
                  >
                    {pushVotesSubmitStatus === 'READY' ? 'Save' : 'Saving'}
                  </Button>
              )}
              {(op === 'ADD' || op === 'UPDATE') && (
                  <Button
                      size="small"
                      type="primary"
                      style={{ marginRight: '5px' }}
                      disabled={pushVotesSubmitStatus !== 'READY'}
                      onClick={() => {
                        this.onSubmitBtnClicked();
                      }}
                  >
                    {pushVotesSubmitStatus === 'READY' ? 'Submit' : 'Submitting'}
                  </Button>
              )}
              {op === 'UPDATE' && (
                  <Dropdown.Button
                      size="small"
                      type="primary"
                      overlay={this._createTradeMenu()}
                      style={{ marginRight: '5px' }}
                      onClick={() => {}}
                  >
                    Trade
                  </Dropdown.Button>
              )}
              {op === 'CLOSE' && (
                  <Button
                      size="small"
                      type="primary"
                      style={{ marginRight: '5px' }}
                      disabled={pushVotesSubmitStatus !== 'READY'}
                      onClick={() => {
                        this.onCloseBtnClicked();
                      }}
                  >
                    {pushVotesSubmitStatus === 'READY' ? 'Close' : 'Closing'}
                  </Button>
              )}
              {op === 'ADJUST' && (
                  <Button
                      size="small"
                      type="primary"
                      style={{ marginRight: '5px' }}
                      disabled={pushVotesSubmitStatus !== 'READY'}
                      onClick={() => {
                        this.onAdjustBtnClicked();
                      }}
                  >
                    {pushVotesSubmitStatus === 'READY' ? 'Adjust' : 'Adjusting'}
                  </Button>
              )}
              <Button
                  size="small"
                  type="primary"
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    this._closeModal();
                  }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
    );

    return (
        <Drawer
            width={'100%'}
            visible={true}
            title={title}
            placement="right"
            onClose={this._closeModal}
            closable={false}
        >
          {!_.isEmpty(errorList) && (
              <Message error header="Errors" list={errorList} />
          )}
          {!_.isEmpty(warnings) && (
              <Message warning header="Warning" list={warnings} />
          )}
          {this._createForm()}
          {this._createCompanyScoreDrawer()}
          {this._createDialogs()}
        </Drawer>
    );
  }
}

export default AddOrUpdateDialog;
